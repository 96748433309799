require('./bootstrap');

window.Isotope = require('isotope-layout/dist/isotope.pkgd.js');
import gsap from "gsap";

$(function() {
    var mobileNav = false;
    window.coinclick = false;

    var $articles = $(".articles").isotope({
        itemSelector: '.col-lg-6',
        percentPosition: true,
        layoutMode: 'masonry',
        masonry: {
          //columnWidth: '.grid-sizer'
        }
    });
    var $employees = $(".cards").isotope({
        itemSelector: '.col-lg-6',
        percentPosition: true,
        layoutMode: 'masonry',
        masonry: {
          //columnWidth: '.grid-sizer'
        }
    });

    $('.burger').click(function() {
        if(!mobileNav){
            $('body').addClass('mobileNav');
            $('nav').addClass('active');
            mobileNav = true;
        }else{
            $('body').removeClass('mobileNav');
            $('nav').removeClass('active');
            mobileNav = false;
        }
    });

    $('.news-filter').on( 'click', 'li', function() {
	  var filterValue = $(this).attr('data-filter');
	  $(".news-filter li").removeClass('active');
	  $(this).addClass('active');
	  $articles.isotope({ filter: filterValue });
    });

    $('.highlights .toggle').click(function() {
        highlights = $(this).parents('.highlights');
        list = $(this).parents('.highlights').find('ul');
        if(list.is(':visible')){
            highlights.removeClass('open');
            list.hide();
            $employees.isotope('layout');
        }else{
            highlights.addClass('open');
            list.fadeIn();
            $employees.isotope('layout');
        }
    });

    $('.coin').click(function(){
        if(window.coinclick){
            window.location = window.location.origin+'/timeline';
        }
    });

    $('.mailing-list-form').submit(function(event) {
        event.preventDefault();
        var input = $(this).find('input');
        $.post('/api/maillist', {email: event.target[0].value}, function() {
            fathom.trackGoal('GTAO9NQM', 0);
            input.addClass('success').attr('placeholder', 'Subscribed!').val('').focus().blur();
        }).fail(function() {
            input.addClass('error').attr('placeholder', 'There was an error.').val('').focus().blur();
        });
    });
    $('.schedule-demo-form').submit(function(event) {
        event.preventDefault();
        var input = $(this).find('input');
        $.post('/api/schedule', {email: event.target[0].value}, function() {
            fathom.trackGoal('BRWHRUSR', 0);
            input.addClass('success').attr('placeholder', 'Demo Request Sent!').val('').focus().blur();
        }).fail(function() {
            input.addClass('error').attr('placeholder', 'There was an error.').val('').focus().blur();
        });
    });

    $('.dropdown').hover(
        function(){
            if($(window).width() > 991) {
                jQuery(this).find('ul').css('display', 'flex').hide().fadeIn(200);
            }
        },
        function(){
            if($(window).width() > 991) {
                jQuery(this).find('ul').fadeOut(200);
            }
        }
    );

    $('.tweet-link').on('click', function(data){
        data.preventDefault();
        var title = $(this).data('title');
        var link = window.location;
        window.open("https://twitter.com/intent/tweet?url=" + link + "&text=" + title + "&via=Vindex&", "twitterwindow", "height=450, width=550, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0");
    });

    $('.linkedin-link').on('click', function(data){
        data.preventDefault();
        var title = $(this).data('title');
        var link = window.location;

        window.open('http://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(link) + '&title=' + title + '','', '_blank, width=500, height=500, resizable=yes, scrollbars=yes');
    });

    var processingReport = false;
    $('#submit-report-form').click(function(){
        // Report Data
        var reportModal = $('#reportModal')
        var report = reportModal.data('report');
        var fullname = reportModal.find('input[name="name"]');
        var email = reportModal.find('input[name="email"]');
        var company = reportModal.find('input[name="company"]');
        var position = reportModal.find('input[name="position"]');
        // Endpoint URL
        var url = window.location.origin+'/api/report';

        reportModal.find('button.download').html('<i class="far fa-circle-notch fa-spin"></i>');
        if(!processingReport){
            processingReport = true;
            $.post(url, { name:fullname.val(), email:email.val(), company:company.val(), position:position.val(), report: report })
            .done(function(data) {
                processingReport = false;
                reportModal.find('button.download').html('Submit');
                reportModal.find('button.download').fadeOut(200);
                reportModal.find('form').fadeOut(200, () => {
                    reportModal.find('.success').fadeIn(200);
                });
                console.debug(JSON.stringify(data));
            }).fail(function(data) {
                processingReport = false;
                reportModal.find('button.download').html('Submit');
                var errors = data.responseJSON.errors;
                if(errors.name){
                    reportModal.find('.name-error').html(errors.name);
                }else{
                    reportModal.find('.name-error').html('');
                }
                if(errors.email){
                    reportModal.find('.email-error').html(errors.email);
                }else{
                    reportModal.find('.email-error').html('');
                }
                if(errors.company){
                    reportModal.find('.company-error').html(errors.company);
                }else{
                    reportModal.find('.company-error').html('');
                }
                if(errors.position){
                    reportModal.find('.position-error').html(errors.position);
                }else{
                    reportModal.find('.position-error').html('');
                }
            })
        }
    });
});

var processingDemo = false;
$('#submit-demo-form').click(function(){
    // Demo Data
    var demoModal = $('#demoModal')
    var firstname = demoModal.find('input[name="firstname"]');
    var lastname = demoModal.find('input[name="lastname"]');
    var email = demoModal.find('input[name="email"]');
    var company = demoModal.find('input[name="company"]');
    var position = demoModal.find('input[name="position"]');
    var details = demoModal.find('textarea[name="details"]');
    // Endpoint URL
    var url = window.location.origin+'/api/schedule';

    demoModal.find('button.schedule').html('<i class="far fa-circle-notch fa-spin"></i>');
    if(!processingDemo){
        processingDemo = true;
        $.post(url, { first_name:firstname.val(), last_name:lastname.val(), email:email.val(), company:company.val(), position:position.val(), details:details.val() })
        .done(function(data) {
            processingDemo = false;
            demoModal.find('button.schedule').html('Submit');
            demoModal.find('button.schedule').fadeOut(200);
            demoModal.find('form').fadeOut(200, () => {
                demoModal.find('.success').fadeIn(200);
                demoModal.find('.please').fadeOut(200);
            });
            console.debug(JSON.stringify(data));
        }).fail(function(data) {
            processingDemo = false;
            demoModal.find('button.schedule').html('Submit');
            var errors = data.responseJSON.errors;
            if(errors.first_name){
                demoModal.find('.firstname-error').html(errors.first_name);
            }else{
                demoModal.find('.firstname-error').html('');
            }
            if(errors.last_name){
                demoModal.find('.lastname-error').html(errors.last_name);
            }else{
                demoModal.find('.lastname-error').html('');
            }
            if(errors.email){
                demoModal.find('.email-error').html(errors.email);
            }else{
                demoModal.find('.email-error').html('');
            }
            if(errors.company){
                demoModal.find('.company-error').html(errors.company);
            }else{
                demoModal.find('.company-error').html('');
            }
            if(errors.position){
                demoModal.find('.position-error').html(errors.position);
            }else{
                demoModal.find('.position-error').html('');
            }
            if(errors.details){
                demoModal.find('.details-error').html(errors.details);
            }else{
                demoModal.find('.details-error').html('');
            }
        })
    }
});

// Close Modal (Button)
$('.close-modal').click(function(){
    $('#reportModal').modal('hide');
    $('#demoModal').modal('hide');
});

let tl = gsap.timeline({ paused: true });

gsap.set(".final", { scale: 0 });

set_animation();

$(".coin").mouseenter(function () {
	tl.restart();
});

$(".coin").mouseleave(function () {
	set_animation();
	tl.pause();
	gsap.to([".coinlogo", $(".pin")], {
		scale: 1,
		ease: "power2.inOut",
		duration: 0.5,
		stagger: {
			amount: 0.5,
		},
	});

	gsap.to([".text", ".ring"], {
		rotate: 0,
		ease: "power2.inOut",
		duration: 0.5,
		stagger: {
			amount: 0.5,
		},
	});

	gsap.to(".text", {
		opacity: 1,
		ease: "power2.out",
		duration: 0.5,
	});

	gsap.to(".ring", {
		scale: 1,
		duration: 0.5,
		ease: "power2.inOut",
	});

	gsap.to(".final", {
		scale: 0,
		duration: 0.5,
		ease: "power2.inOut",
	});
});

function set_animation() {
	tl.clear();
	tl.to([$(".coin .pin").slice(0, 15)], {
		scale: 0.9,
		ease: "power4.in",
		duration: 0.5,
		stagger: {
			amount: 0.5,
		},
	});

	tl.to(
		".text",
		{
			rotate: Math.floor(Math.random() * 90 + 45),
			duration: 1,
			ease: "power1.inOut",
		},
		"<"
	);

	tl.to(
		".ring-outer",
		{
			rotate: Math.floor(Math.random() * 135 + 90) * -1,
			duration: 1,
			ease: "power2.inOut",
		},
		"<"
	);

	tl.to([$(".coin .pin").slice(10, 29)], {
		scale: 0.9,
		ease: "power4.in",
		duration: 0.5,
		stagger: {
			amount: 0.5,
		},
	});

	tl.to(
		".text",
		{
			rotate: Math.floor(Math.random() * 90 + 45) * -1,
			duration: 1,
			ease: "power1.inOut",
		},
		"<"
	);

	tl.to(
		".ring-center",
		{
			rotate: Math.floor(Math.random() * 135 + 90),
			duration: 1,
			ease: "power2.inOut",
		},
		"<"
	);

	tl.to([".coinlogo", $(".pin")], {
		scale: 0,
		ease: "power2.in",
		duration: 0.5,
		stagger: {
			from: "random",
			amount: 0.5,
		},
	});

	tl.to(
		".text",
		{
			rotate: Math.floor(Math.random() * 90 + 45),
			duration: 1,
			ease: "power1.inOut",
		},
		"<"
	);

	tl.to(
		".ring-inner",
		{
			rotate: Math.floor(Math.random() * 135 + 90) * -1,
			duration: 1,
			ease: "power2.inOut",
		},
		"<"
	);

	tl.to(
		".final",
		{
			scale: 1,
			ease: "power2.out",
			duration: 0.5,
		},
		"-=0.2"
	);

	tl.to(
		".text",
		{
			opacity: 0,
			duration: 0.5,
			ease: "power2.in",
		},
		"<"
	);

	tl.to(
		[".ring-center", ".ring-inner"],
		{
			scale: 1.1,
			duration: 0.5,
			ease: "power2.inOut",
			stagger: 0.05,
		},
		"<"
	);

    tl.call(function(){ coinclick = true;
    $('.coin').css('cursor','pointer'); })
}
